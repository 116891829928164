import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Hero,
} from '@components';
import {
  Gallery,
  WhyUs,
  Sections,
} from '../components/PageTemplates/AboutPage';

const AboutPage = ({ data }) => {
  const { markdownRemark: { frontmatter = {} } } = data;
  const {
    hero,
    whyUsMore,
    gallery,
    sections,
    seo,
  } = frontmatter;
  return (
    <>
      <Hero {...hero} seo={seo} />
      <WhyUs {...whyUsMore} />
      <Gallery {...gallery} />
      <Sections sections={sections} />
    </>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        hero {
          title
          markdown
        }
        whyUsMore {
          title
          items {
            title
            markdown
          }
        }
        gallery {
          visible
          title
          photos {
            image {
              alt
              src {
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        sections {
          color
          first {
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          second {
            title
            markdown
          }
        }
        seo {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
